<template>
  <div class="iss-main-grid">
<!--     筛选区域  -->
      <div class="statistics-card mb-16">
        <a-row :gutter="16">
          <a-col :span="4"></a-col>
          <a-col :span="20" style="text-align: right">
            <a-space :size="16">
              <a-button
                  :type="dayButton === 1 && 'primary'"
                  @click="handleFnCilckDay(1)"
              >全部</a-button
              >
              <a-button
                  :type="dayButton === 0 && 'primary'"
                  @click="handleFnCilckDay(0)"
              >今天</a-button
              >
              <a-button
                  :type="dayButton === 7 && 'primary'"
                  @click="handleFnCilckDay(7)"
              >近7天</a-button
              >
              <a-button
                  :type="dayButton === 14 && 'primary'"
                  @click="handleFnCilckDay(14)"
              >近14天
              </a-button>
              <a-button
                  :type="dayButton === 30 && 'primary'"
                  @click="handleFnCilckDay(30)"
              >近30天</a-button
              >
              <a-range-picker
                  format="YYYY-MM-DD"
                  v-model:value="TimePick"
                  @change="handleFnOk"
                  class="mr-16"
              >
                <template #suffixIcon>
                  <CreditCardOutlined />
                </template>
              </a-range-picker>
            </a-space>
          </a-col>
        </a-row>
      </div>
<!--    数据总览  -->
      <a-row :gutter="16" class="mb-16 rowClass">
        <a-col :span="5">
          <div class="statistics-card" flex-attr="task">
            <div class="statistics-title">
              <div class="mun fs-30 ">
                {{ dataBoard.registerCount }}
                <span class="yuan">人</span>
              </div>
              <div class="fontStyle">
                注册用户数
              </div>
            </div>
            <div class="imgBox">
              <UserOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="statistics-card" flex-attr="user">
            <div class="statistics-title">
              <div class="mun fs-30">
                {{ dataBoard.activateCount }}
                <span class="yuan">人</span>
              </div>
              <div class="fontStyle">
                激活用户数
              </div>
            </div>
            <div class="imgBox">
              <FileTextOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="statistics-card" flex-attr="company">
            <div class="statistics-title">
              <div class="mun fs-30">
                {{ dataBoard.subscribeCount }}
                <span class="yuan">人</span>
              </div>
              <div class="fontStyle">
                付费订阅用户数
              </div>
            </div>
            <div class="imgBox">
              <SmileOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="5">
          <div class="statistics-card" flex-attr="user">
            <div class="statistics-title">
              <div class="mun fs-30">
                {{ dataBoard.unsubscribeCount }}
                <span class="yuan">人</span>
              </div>
              <div class="fontStyle">
                退订用户数
              </div>
            </div>
            <div class="imgBox">
              <SmileOutlined  class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistics-card" flex-attr="company">
            <div class="statistics-title">
              <div class="mun fs-30">
                {{ dataBoard.logoffCount }}
                <span class="yuan">人</span>
              </div>
              <div class="fontStyle">
                注销用户数
              </div>
            </div>
            <div class="imgBox">
              <FileTextOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
      </a-row>


<!--     分页 -->
      <div class="iss-search-wrap">
        <search-form :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
        <grid
            ref="gridRef"
            allow-selection
            :columns="columns"
            :code="$route.path"
            :url="url"
            :search="search"
            :btn-operation="btnOperation"
            :scroll="{ x: 1800, y: gridHeight }"
        >
          <template #avatar="{ record }">
            <div  @click="handleName(record)" class="cursor-p" >
              <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
              <a-avatar :size="30" v-else>
                <template #icon><UserOutlined /></template>
              </a-avatar>
            </div>
          </template>

<!--       昵称   -->
          <template #userName="{ record }">
            <div  @click="handleName(record)" class="activeColor cursor-p" v-if="record.userName">{{record.userName}}</div>
            <div  @click="handleName(record)" class="cursor-p" v-else>暂无</div>
          </template>

          <template #orderUserNum="{ record }">
            <div class="activeColor">{{record.orderUserNum}}人</div>
          </template>

          <template #sex="{ record }">
            <div v-if="record.sex === '1'">男</div>
            <div v-if="record.sex === '0'">女</div>
          </template>

          <template #packageId="{ record }">
            <div>{{record.packageName}}</div>
<!--            <div v-if="record.packageType === '8'">月卡</div>-->
<!--            <div v-if="record.packageType === '9'">季卡</div>-->
<!--            <div v-if="record.packageType === '10'">年卡</div>-->
          </template>
          <template #status="{ record }">
            <div v-if="record.status === 1">注册</div>
            <div v-if="record.status === 0">注销</div>
          </template>

          <template #subscribeStatus="{ record }">
            <div v-if="record.subscribeStatus === 1">订阅</div>
            <div v-if="record.subscribeStatus === 0">到期</div>
          </template>

<!--          <template #beginnerGuide="{ record }">-->
<!--            <div v-if="record.beginnerGuide === 1">已激活</div>-->
<!--            <div v-if="record.beginnerGuide === 0">未激活</div>-->
<!--          </template>-->

          <template #operation="{ record }">
            <operation :options="options" :record="record">
              <template #goDetail>
                <div class="primary-text cursor-p" @click="handleGotoView($event, record)">详情</div>
              </template>
              <template #more>
                <div class="primary-text cursor-p">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent> 更多操作 </a>
                    <template #overlay>
                      <a-menu @click="clickOptions($event, record)">
                        <a-menu-item
                            v-for="item in itemOption"
                            :key="item.value"
                        >
                          <span class="padding-10">{{ item.label }}</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </operation>
          </template>
        </grid>
      </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRefs} from 'vue';
// Tooltip as ATooltip,
import {Row, Col, DatePicker, Space, Modal,   Menu,  Dropdown, Avatar, message } from 'ant-design-vue';
import {
  UserOutlined,
  FileTextOutlined,
  SmileOutlined
} from '@ant-design/icons-vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
// import contactApi from '@/api/contact.js';
import userApi from '@/api/user.js';
import {useRouter} from "vue-router";
import SearchForm from '@/components/searchForm';
import moment from "moment/moment";
import {downloadExcel, setAvatar } from '@/utils';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ADropdown: Dropdown,
    // ATooltip,
    ARangePicker: DatePicker.RangePicker,
    ASpace: Space,
    UserOutlined,
    FileTextOutlined,
    SmileOutlined,
    Operation,
    Grid,
    SearchForm,
    AAvatar: Avatar,


  },
  setup() {
    const gridRef = ref();
    const router = useRouter();
    const state = reactive({
      search: {},
      TagList: [],
      dataBoard: {},
      TimePick: [
        moment(new Date(new Date().getTime() - 86400000 * 7)),
        moment(new Date(new Date().getTime() - 86400000)),
      ],
      TimeData: {
        endTime: '',
        beginTime: '',
      },
      dayButton: 1,
    });

    const clickOptions = ({ key }, record) => {
      switch (key) {
        case 'order':
          router.push(`/user/${record.id}?activeKey=3`)
          break;
        case 'invitation':
          router.push(`/user/${record.id}?activeKey=2`)
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要封禁该账号吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
      }
    };

    const getDataBoard = () => {
      let parmas = {
        type: 1,
        beginTime: '',
        endTime: '',
        userId: 0,
      }
      if (state.dayButton === 1) {
        parmas.type = 1
        parmas.beginTime = ''
        parmas.endTime = ''
      } else {
        parmas.type = 0
        parmas.beginTime =  state.TimeData.beginTime
        parmas.endTime = state.TimeData.endTime

      }

      // 用户列表表头统计数据
      userApi.getUserDataBoard('', parmas).then((res) => {
        if (res) {
          state.dataBoard = res
        }
      });

    };

    onMounted(()=>{
      getDataBoard()
    })

    return {
      setAvatar,
      gridRef,
      ...toRefs(state),
      itemOption: [
        { value: 'order', label: 'ta的订单' },
        { value: 'invitation', label: 'ta的邀请' },
        { value: 'disable', label: '封禁账号' },
      ],
      items: [
        {
          key: 'sex',
          label: '性别',
          type: 'select',
          dataset: [
            { label: '男', value: 1 },
            { label: '女', value: 0 },
          ],
        },
        {
          key: 'status',
          label: '用户状态',
          type: 'select',
          dataset: [
            { value: 0, label: '注销' },
            { value: 1, label: '注册' },
          ],
        },
        {
          key: 'packageId',
          label: '订阅类型',
          type: 'select',
          urlTepy: 'post',
          urlParams: {
          types: [0],
          typeDetails: []
        },
          valueKey: 'id',
          labelKey: 'packageName',
          url: `${userApi.getPackageInfoTypeDetailList}`,
        },
        {
          key: 'subscribeStatus',
          label: '订阅状态',
          type: 'select',
          dataset: [
            { value: 1, label: '订阅' },
            { value: 0, label: '到期' },
          ],
        },
        {
          key: 'beginnerGuide',
          label: '激活状态',
          type: 'select',
          dataset: [
            { value: 1, label: '已激活' },
            { value: 0, label: '未激活' },
          ],
        },
        {
          key: 'channelSource',
          label: '来源渠道',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          url: `${userApi.treeList}?code=CHANNEL_SOURCE`,
          linkedKey: 'city',
        },
        {
          key: 'ipAddress',
          label: '地区',
          type: 'select',
          valueKey: 'itemNameCn',
          labelKey: 'itemNameCn',
          url: `${userApi.treeList}?code=BELONGING_PLACE`,
        },
        {
          key: 'condition',
          label: '昵称或联系方式',
          type: 'inputName',
        },

        // {
        //   key: 'province',
        //   label: '省份',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   url: `${dictionaryApi.provinceUrl}?code=CHINA_AREA`,
        //   linkedKey: 'city',
        // },

      ],
      columns: [
        { dataIndex: 'avatar', title: '头像', width: 60, ellipsis: true,
          slots: { customRender: 'avatar' },
        },
        { dataIndex: 'userName', title: '昵称', width: 120, ellipsis: true,
          slots: { customRender: 'userName' },
        },
        { dataIndex: 'sex', title: '性别', width: 80, ellipsis: true,
          slots: { customRender: 'sex' },
        },
        { dataIndex: 'id', title: '用户ID', width: 200, ellipsis: true },
        { dataIndex: 'createTime', title: '注册时间', width: 200, ellipsis: true },
        { dataIndex: 'mobile', title: '联系方式', width: 150, ellipsis: true },
        {
          dataIndex: 'email',
          title: '电子邮箱',
          width: 160,
          ellipsis: true,
        },
        { dataIndex: 'orderUserNum', title: '邀请人数', width: 120, ellipsis: true,
          slots: { customRender: 'orderUserNum' },
        },
        { dataIndex: 'openId', title: 'open ID', width: 250, ellipsis: true },
        { dataIndex: 'memberExpireTime', title: '订阅到期时间', width: 200, ellipsis: true },
        { dataIndex: 'packageId', title: '订阅类型', width: 120, ellipsis: true,
          slots: { customRender: 'packageId' },
        },
        // { dataIndex: 'beginnerGuide', title: '激活状态', width: 120, ellipsis: true,
        //   slots: { customRender: 'beginnerGuide' }, },
        { dataIndex: 'status', title: '用户状态', width: 120, ellipsis: true,
          slots: { customRender: 'status' }, },
        { dataIndex: 'subscribeStatus', title: '订阅状态', width: 120, ellipsis: true,
          slots: { customRender: 'subscribeStatus' },
        },
        { dataIndex: 'channelSource', title: '来源渠道', width: 120, ellipsis: true },
        { dataIndex: 'ipAddress', title: '地区', width: 120, ellipsis: true },
        { dataIndex: 'goldNum', title: '金币余额', width: 120, ellipsis: true },
        {
          dataIndex: 'lastLoginTime',
          title: '最后登录时间',
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 130,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'export',
          label: '导出全部数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: () => {
            userApi.exportSysUserList('', {exportCheckStatus: 1})
                  .then(({ name, data }) => downloadExcel(name, data));

          },
        },
        {
          type: 'export',
          label: '导出批量数据',
          icon: 'ExportOutlined',
          permission: 'contact:export',
          fnClick: ids => {
            if (ids.length) {
              userApi.exportSysUserList('',{ userIds: [...ids], exportCheckStatus: 0 })
                  .then(({ name, data }) => downloadExcel(name, data));
            } else {
              message.info('请至少选中一条数据！');
            }
          },
        },
      ],
      options: [
        {
          type: 'goDetail',
          slotName: 'goDetail',
          title: '详情',
          permission: 'user:goDetail',
          fnClick: ({ id }) => {
            // router.push({
            //   name: '',
            //   query: {
            //     id,
            //   },
            // });
            console.log(id, router);
          },
        },
        {
          type: 'more',
          slotName: 'more',
          title: '更多操作',
          permission: 'user:more',
          fnClick: ({ id }) => {
            console.log(id);
          },
        },
      ],
      url: userApi.pageUrl,
      gridHeight: document.body.clientHeight - 260,
      clickOptions,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleName: (record) => {
        return router.push(`/user/${record.id}`)
      },
      handleGotoView: ($event, record)=> {
        return  router.push(`/user/${record.id}`)
      },

      // 表头统计数据筛选
      handleFnCilckDay(num) {
        state.dayButton = num;
        const strTime = num === 0 ? 0 : 86400000 * num;
        const endTime = num === 0 ? 0 : 86400000;
        state.TimePick[0] = moment(new Date(new Date().getTime() - strTime));
        state.TimePick[1] = moment(new Date(new Date().getTime() - endTime));

        state.TimeData.beginTime = state.TimePick[0]
            .format('YYYY-MM-DD 00:00:00')
            .toString();

        state.TimeData.endTime = state.TimePick[1]
            .format('YYYY-MM-DD HH:mm:ss')
            .toString();

        getDataBoard()
      },

    }

  },

}
</script>

<style scoped lang="less">
.iss-main-grid {
  padding: 0px 10px;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}

.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    width: 85%;
    font-size: 16px;
    word-wrap: break-word;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    width: 15%;
    .icon {
      font-size: 28px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontStyle {
  height: 24px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  //font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
.yuan {
  font-size: 14px;
}

</style>